import React, { useState } from 'react'
//import {useValidation} from "../hooks/useValidation"
//import {login} from "../auth/index"
import useInput from '../hooks/useInput'
import { useHistory } from 'react-router-dom'

const passwordValidator = (value) => {
    let validLength = value.trim().length >= 8
    let hasNumber = /\d/.test(value)
    let upperCase = value.toLowerCase() !== value
    let lowerCase = value.toUpperCase() !== value
    //let specialChar =  /[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/.test(value)
    if (validLength && hasNumber && upperCase && lowerCase) {
        return true
    } else {
        return false
    }
}

const matchValidator = (value1, value2) => {
    if (value1 === value2) {
        return true
    } else {
        return false
    }
}

const Register = (props) => {
    const history = useHistory();
    //const [newUser, setNewUser] = useState('')
    const [first, setFirst] = useState('')
    const [last, setLast] = useState('')
    const [org, setOrg] = useState('')

    let allowed_domains = [
        '.edu', 'bnl.gov', 'broadinstitute.org',
        'edu.au', 'edu.tr', 'edu.sg', 'edu.qa', 'edu.ar', 'edu.sa', 'edu.ph', 'edu.pl', 'edu.fc.up.pt',
        'ac.uk', 'ac.jp', 'ac.kr', 'ac.in', 'ac.nz',
        'utoronto.ca', 'queensu.ca', 'ibt.unam.mx', 'unam.mx', 'uc.cl', 'usp.br',
        'epfl.ch', 'bsse.ethz.ch', 'psi.ch', 'unibas.ch',
        'leipzig.de', 'geomar.de', 'uni-greifswald.de', 'mpi-marburg.mpg.de',
        'mdc-berlin.de', 'biotech.rwth-aachen.de', 'imb-mainz.de', 'pluto.uni-freiburg.de',
        'psl.eu', 'espci.psl.eu', 'crg.eu',
        'uva.nl', 'wur.nl', 'rug.nl', 'leidenuniv.nl',
        'ugent.be', 'ifsc.usp.br', 'dtu.dk', 'unideb.hu', 'student.pmf.hr', 'chem.pmf.hr', 'biol.pmf.hr',
        'espci.fr', 'pasteur.fr',
        'studenti.polito.it', 'unibo.it', 'unipv.it', 'unimib.it', 'unitn.it',
        'biotek.lu.se', 'scilifelab.se', 'dac.unicamp.br',
        'um5.ac.ma', 'udl.cat', 'autonoma.cat', 'ehu.eus', 'csiro.au', 'igib.res.in', 'ibmb.csic.es',
        'radboudumc.nl', 'chem.bg.ac.rs', 'oulu.fi', 'ukm.edu.my', 'csic.es', 'fz-juelich.de',
        'udec.cl', 'unipd.it', 'pnnl.gov', 'uv.es', 'ucdconnect.ie', 'unisa.ac.za', 'kribb.re.kr',
	'mail.mcgill.ca', 'hhu.de', 'uit.no', 'univ-nantes.fr', 'mdanderson.org', 'kuleuven.be',
        'uwaterloo.ca', 'gs.ncku.edu.tw', 'ualberta.ca', 'utu.fi', 'tugraz.at', 'erdc.dren.mil',
	'aalto.fi', 'charusat.edu.in', 'umanitoba.ca', 'itqb.unl.pt', 'mail.huji.ac.il',
	'uni-halle.de', 'st.knust.edu.gh', 'potomacschool.org', 'tum.de', 'unine.ch',
	'imm.cnrs.fr', 'dsc.naist.jp', 'student.ubc.ca', 'tudelft.nl', 'up.ac.th', 'uu.nl',
    ]

    const regex = `^.+@.*(${allowed_domains.join("|")})$`

    const {
        value: password1,
        valid: password1Valid,
        hasError: password1Error,
        valueChangeHandler: password1Change,
        inputBlurHandler: password1Blur
    } = useInput((value) => passwordValidator(value))

    const {
        value: password2,
        valid: password2Valid,
        hasError: password2Error,
        valueChangeHandler: password2Change,
        inputBlurHandler: password2Blur
    } = useInput((value) => matchValidator(password1, value))

    const {
        value: email1,
        valid: email1Valid,
        hasError: email1Error,
        valueChangeHandler: email1Change,
        inputBlurHandler: email1Blur
    } = useInput((value) => value.trim() ? true : false)

    const {
        value: email2,
        valid: email2Valid,
        hasError: email2Error,
        valueChangeHandler: email2Change,
        inputBlurHandler: email2Blur
    } = useInput((value) => matchValidator(email1, value))

    let validForm = false
    if (password1Valid && password2Valid && email1Valid && email2Valid) {
        //setNewUser({...newUser, email: email2, password: password2})
        validForm = true
    }


    const registerSubmit = e => {
        e.preventDefault()
        fetch('/api/register', {
            method: 'post',
            url: '/register',
            body: JSON.stringify({
                first: first,
                last: last,
                org: org,
                email: email2,
                password: password2
            }),
            headers: {
                'content-type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(data => {
                console.log(data)
                alert(Object.entries(data))
                if (data.status === 200) {
                    history.push('/login')
                }
            })
    }

    //   if (res.status === 200){
    //     history.push('/login')
    //     res.json()
    //   }

    return (
        <div className=" register container-fluid">
            <div className="container page-header move-right">
                <h1 className="dark-grey">Registration<small></small></h1>
            </div>
            <br />
            <section className="container">
                <div className="container-page">
                    <form method="POST" onSubmit={registerSubmit} autoComplete="on">
                        <div className="row container">
                            <div className="form-group col-md-6">
                                <input
                                    type="text"
                                    id="firstName"
                                    className="form-control"
                                    placeholder="First Name"
                                    //onChange={e => setNewUser({ ...newUser, first: e.target.value })}
                                    onChange={e => setFirst(e.target.value)}
                                    required autoFocus minLength='2'
                                />
                            </div>
                            <div className="form-group col-md-6">

                                <input
                                    type="text"
                                    id="lastName"
                                    className="form-control"
                                    placeholder="Last Name"
                                    // onChange={e => setNewUser({ ...newUser, last: e.target.value })}
                                    onChange={e => setLast(e.target.value)}
                                    required minLength='2'
                                />
                            </div>
                        </div>
                        <br />
                        <div className="row container">

                            <div className="form-group col-md-6">
                                <input
                                    type="email"
                                    id="inputEmail"
                                    className="form-control"
                                    placeholder="Email Address"
                                    onChange={email1Change}
                                    onBlur={email1Blur}
                                    value={email1}
                                    pattern={regex}
                                    required
                                />
                                {email1Error && <span>Please enter an email</span>}
                            </div>
                            <div className="form-group col-md-6">

                                <input
                                    type="email"
                                    id="emailConfirm"
                                    className="form-control"
                                    placeholder="Confirm Email Address"
                                    onChange={email2Change}
                                    onBlur={email2Blur}
                                    value={email2}
                                    required
                                />
                                <span>
                                    {email2Error && <span style={{ color: 'red' }}>Emails do not match</span>}
                                </span>
                            </div>
                        </div>
                        <br />
                        <div className="row container">
                            <div className="form-group col-md-6">
                                <input
                                    type="text"
                                    id="org"
                                    className="form-control"
                                    placeholder="Organization"
                                    //onChange={e => setNewUser({ ...newUser, org: e.target.value })}
                                    onChange={e => setOrg(e.target.value)}
                                    required minLength='3'
                                />
                            </div>
                            <div className="form-group col-md-6">
                                <input
                                    type="password"
                                    id="password"
                                    className="form-control"
                                    placeholder="Password"
                                    onChange={password1Change}
                                    onBlur={password1Blur}
                                    value={password1}
                                    required
                                />
                                <span>
                                    {password1Error && <span style={{ color: 'red' }}>A strong password is &gt;8 and contains Upper, lower, and number characters.</span>}
                                </span>
                            </div>
                        </div>
                        <br />
                        <div className="row container">
                            <div className="form-group col-md-6">
                            </div>
                            <div className="form-group col-md-6">
                                <input
                                    type="password"
                                    id="confirmPassword"
                                    className="form-control"
                                    placeholder="Confirm Password"
                                    onChange={password2Change}
                                    onBlur={password2Blur}
                                    value={password2}
                                    required
                                />
                                <span>
                                    {password2Error && <span style={{ color: 'red' }}>Passwords do not match</span>}
                                </span>
                            </div>
                            <div className="col-md-6 text-justify">
                                <button
                                    className="w-20 btn btn-lg btn-primary"
                                    id='submitBtn'
                                    type="submit"
                                    disabled={!validForm}>
                                    Register
                                </button>
                            </div>
                        </div>
                        <hr />
                        <div className="col-md-9 text-justify">
                            <h3 className="dark-grey"><strong>Terms and Conditions</strong></h3>
                            <p>
                                MutCompute is for academic use only.
                                User must provide an academic email address for valid registration.
                            </p>
                            <p>
                                For any questions, issues, or collaborations please contact <a href="mailto:danny.diaz@utexas.edu?subject=Protein_NN_accessibility">
                                    danny.diaz@utexas.edu.</a>
                            </p>
                            <p>
                                For any commercial inquiries please contact licensing@otc.utexas.edu <a href="mailto:licensing@otc.utexas.edu">
                                    licensing@otc.utexas.edu.</a>
                            </p>
                            <p>
                                By clicking "Register" you agree to our
                                <a href="/terms"> terms and conditions. </a>
                            </p>
                            <p>
                                Please visit the <a href="/faq">FAQ</a> for additional details.
                            </p>
                        </div>
                    </form>
                </div>
            </section>
        </div>
    )
}

export default Register
