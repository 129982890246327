import React from 'react';
import Compvis3 from './compvis3'

const PetaseWt = (props) => {
    return (
        <div>
        <Compvis3 id='5xjh'></Compvis3>
        </div>
    )
}

export default PetaseWt