import React from 'react';
import Compvis3 from './compvis3'

const PetaseThermo = () => {
    return (
        <div>
        <Compvis3 id='6ij6'></Compvis3>
        </div>
    )
}

export default PetaseThermo